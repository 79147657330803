import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import globalVariables from "../globalVariables"
import { TitleH2 } from "../elements/Title"

const CardStyled = styled.a`
  display: block;
  z-index: 1;
  text-decoration: none;
  @media (min-width: ${globalVariables.minTablet}) {
    flex: 0 0 50%;
    .gatsby-image-wrapper {
      transition: all 0.2s ease-in-out;
    }
    &:hover {
      .gatsby-image-wrapper {
        transform: scale(1.1);
      }
      .title4 {
        color: ${props => props.theme.primary};
      }
    }
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    flex: 0 0 33.33%;
  }
`

const CardContainer = styled.div`
  margin: 10px 0 20px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (min-width: ${globalVariables.minTablet}) {
    margin: 10px 10px 22px 10px;
  }
`

const CardItem = styled.div`
  flex: 1 1 100%;
`
const CardContent = styled.div`
  flex: 1 1 100%;
  padding: 22px;
  border-right: 1px solid #dedede;
  border-left: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  height: 170px;
  .title4 {
    font-size: 20px;
    overflow: hidden;
    position: relative;
    line-height: 1.2em;
    max-height: 2.8rem;
    margin-right: -10px;
    padding-right: 1em;
    &:before {
      content: "...";
      position: absolute;
      right: 0;
      bottom: 0;
    }
    &:after {
      content: "";
      position: absolute;
      right: 0;
      width: 1em;
      height: 1em;
      margin-top: 0.2em;
      background: white;
    }
  }
`

const ImageWrapper = styled.div`
  height: 200px;
  position: relative;
  overflow: hidden;
`

const Text = styled.p`
  margin-top: -10px;
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
  line-height: 1.2em;
  max-height: 4.8em;
  text-align: justify;
  margin-right: -10px;
  padding-right: 1em;
  color: ${props => props.theme.bodyColor};
  &:before {
    content: "...";
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
  }
`

const Card = ({ data }) => {
  return (
    <CardStyled href={data.node.path}>
      <CardContainer>
        <CardItem>
          <ImageWrapper>
            <Img
              fluid={data.node.acf.image_ebook.localFile.childImageSharp.fluid}
            />
          </ImageWrapper>
        </CardItem>
        <CardContent>
          <TitleH2 className="title4">{data.node.acf.titre_banniere}</TitleH2>
          <Text>{data.node.acf.texte_vignette}</Text>
        </CardContent>
      </CardContainer>
    </CardStyled>
  )
}

export default Card
