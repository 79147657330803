import React from "react"
import styled from "styled-components"

import Section from "../elements/Section"
import Container from "../elements/Container"
import globalVariables from "../globalVariables"
import Card from "./Card"

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
  }
`

const EbookList = ({ data }) => {
  return (
    <Section padding="45px 0" paddingMobile="30px 0" id="articles-liste">
      <Container>
        <Cards>
          {data.map((ebook, index) => (
            <Card data={ebook} key={index} />
          ))}
        </Cards>
      </Container>
    </Section>
  )
}

export default EbookList
