import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import EbookList from "../components/Ebooks"
import Contact from "../components/Home/Contact"
import BannerV2 from "../components/elements/BannersV2/BannerV2"

const Ebooks = ({ data, location }) => {
  const { ebook, ebooks, bloc_contact } = data

  const bannerLeftCardContent = {
    mainContent:
      "Découvrez nos livres blancs spécialisés sur les divers thèmes du marketing digital et téléchargez les gratuitement.",
    titleFirstLine: "Nos",
    titleSecondLine: "livres blancs",
  }

  return (
    <Layout
      location={location.pathname}
      crumbLabel="Livres blancs"
      locationGTM={location.href}
    >
      <SEO
        title={ebook.acf.title}
        description={ebook.acf.meta_description}
        lang="fr"
        url={location.pathname}
      />
      <BannerV2
        bannerLeftCardContent={bannerLeftCardContent}
        hasBreadCrumb
        isEbooksPage
      />
      <EbookList data={ebooks.edges} />
      <Contact
        title={bloc_contact.acf.titre_contact}
        text={bloc_contact.acf.texte_contact}
        location={location.href}
      />
    </Layout>
  )
}

export default Ebooks

export const query = graphql`
  query {
    ebook: wordpressPage(id: { eq: "df35d1b0-3ac7-5e23-b4bd-76c6f59b37c4" }) {
      acf {
        title
        meta_description
        titre_banniere
        texte_banniere
      }
    }
    ebooks: allWordpressWpEbooks {
      edges {
        node {
          path
          acf {
            titre_banniere
            texte_vignette
            image_ebook {
              localFile {
                childImageSharp {
                  fluid(srcSetBreakpoints: 400, maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    bloc_contact: wordpressAcfBlocs(
      id: { eq: "76393d63-8408-5238-b201-7f73d6fe7a78" }
    ) {
      acf {
        titre_contact
        texte_contact
      }
    }
  }
`
